import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import useQueryString from "../social-supermarket/hooks/useQueryString"
import GiftSelectionPage from "../social-supermarket/pages/gift-selection/GiftSelectionPage"

const Container = styled.div``

interface Props {}

const PrivateGiftSelector: FC<Props> = () => {
  const [responseKey, setResponseKey] = useQueryString("key", "")
  return <GiftSelectionPage responseKey={responseKey.replace("/", "")} />
}

export default PrivateGiftSelector
